<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <validation-observer v-slot="{invalid}">
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--5">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">
                                    <router-link to="/manage/manage-account"><a href=""><i class="fa fa-arrow-left mr-3 text-dark"></i></a></router-link>
                                    {{ tt('update_account') }}
                                </h3>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="update" :disabled="btnupdate.onLoading || invalid">
                                    <span v-if="btnupdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                                    <span v-else>
                                        <span>{{ tt('update') }}</span>
                                    </span>
                                </base-button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="mt-5">
                            <h6 class="heading-small text-muted mb-4">{{ tt('user_information') }}</h6>
                            <div class="row ml-3">
                                <div class="col-6">
                                    <base-input :label="tt('name')" :name="tt('name')" :placeholder="tt('name')" v-model="manageAccount.name"></base-input>
                                    <base-input :label="tt('username')" :name="tt('username')" :placeholder="tt('username')" v-model="manageAccount.username"></base-input>
                                    <base-input :label="tt('email_address')" :name="tt('email_address')" :placeholder="tt('email_address')" v-model="manageAccount.email"></base-input>
                                </div>
                                <div class="col-6">
                                    <label class="form-control-label ml-3">{{ tt('catalog_type') }}</label>
                                    <el-select class="col-12 mb-2" v-model="manageAccount.rel_user_cat_type" multiple filterable placeholder="Select">
                                        <el-option
                                        v-for="ct in catalogType"
                                        :key="ct.code"
                                        :label="ct.code +' - '+ ct.description"
                                        :value="ct.code">
                                        </el-option>
                                    </el-select>
                                    <label class="form-control-label mt-4 ml-3">{{ tt('roles') }}</label>
                                    <el-select class="col-12 mb-2" v-model="manageAccount.roles" multiple filterable placeholder="Select">
                                        <el-option
                                        v-for="r in roles"
                                        :key="r.name"
                                        :label="r.name"
                                        :value="r.name">
                                        </el-option>
                                    </el-select>
                                    <label class="form-control-label ml-3 mt-4">{{ tt('company') }}</label>
                                    <el-select class="col-12 mb-2" v-model="manageAccount.company_code" filterable placeholder="Select">
                                        <el-option
                                        v-for="cc in companyCode"
                                        :key="cc.company_code"
                                        :label="cc.company_code +' - '+ cc.company_description"
                                        :value="cc.company_code">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <hr>
                            <h6 class="heading-small text-muted mb-4">{{ tt('password') }}</h6>
                            <div class="row ml-3">
                                <div class="col-6">
                                    <base-input :label="tt('password')" :name="tt('password')" :placeholder="tt('password')" v-model="manageAccount.password"></base-input>
                                </div>
                                <div class="col-6">
                                    <base-input :label="tt('password_confirmation')" :name="tt('password_confirmation')" :placeholder="tt('password_confirmation')" v-model="manageAccount.password_confirmation"></base-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </validation-observer>
        <!-- <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" :placeholder="tt('table')" v-model="manageAccount.table"></base-input>

                <label class="form-control-label">{{ tt('function') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('function')" :placeholder="tt('function')" v-model="manageAccount.function"></base-input>

                <label class="form-control-label">{{ tt('input') }}</label>
                <base-input :name="tt('input')" :placeholder="tt('input')" v-model="manageAccount.input"></base-input>

                <label class="form-control-label">{{ tt('output') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('output')" :placeholder="tt('output')" v-model="manageAccount.output"></base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal> -->
    </div>
</template>
<script>
    import Api from '@/helpers/api';
    import manageAccount from '@/services/manage/manageAccount.service';
    import catalogType from '@/services/master/catalogType.service';
    import roles from '@/services/manage/manageRole.service';
    import companyCode from '@/services/master/companyCode.service';

    export default {        
        data() {
            return {                
                btnupdate: {
                    onLoading: false
                },
                form: {
                    add: true,
                    title: this.tt('add_sap_synchron_task'),
                    show: false
                }, 
                table: {
                    data: []
                },        
                manageAccount: {},
                catalogType: {},
                companyCode: [],
                roles: [], 
            }
        },
        mounted() {
            this.get()
            this.get_company()
            this.getCatalogType()
            this.getRoles()
        },
        methods: {
            get() { 
                let context = this;           
                Api(context, manageAccount.show(this.$route.query.data)).onSuccess(function(response) {    
                    context.manageAccount = response.data.data;
                    var roles = [];
                    var rel_user_cat_type = [];
                    response.data.data.roles.forEach(function(item) {
                        roles.push(item.name)
                    })
                    response.data.data.rel_user_cat_type.forEach(function(item) {
                        rel_user_cat_type.push(item.catalog_type_code)
                    })

                    context.manageAccount.roles = roles
                    context.manageAccount.rel_user_cat_type = rel_user_cat_type
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            get_company() {
                let context = this;               
                Api(context, companyCode.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.companyCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.companyCode = [];
                    }
                })
                .call()
            },
            getCatalogType() { 
                let context = this;               
                Api(context, catalogType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.catalogType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },
            getRoles() { 
                let context = this;               
                Api(context, roles.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.roles = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.roles = [];
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                }).call()
            },

            update() {                      
                let context = this;
                context.btnupdate.onLoading = true;

                Api(context, manageAccount.update(context.manageAccount.id, context.manageAccount)).onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.$router.push({ path: '/manage/manage-account'});
                }).onError(function(error) {                    
                    context.btnupdate.onLoading = false;
                    context.$notify({
                        message: error.response.data.data,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
                    context.btnupdate.onLoading = false;   
                })
                .call();
            },
        },
    };
</script>
<style></style>
